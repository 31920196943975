import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request as ExpressRequest } from 'express';
import { MetaService } from './meta.service';

@Injectable({
    providedIn: 'root',
})
export class FeatureDetectionService {
    private readonly prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-', ''];

    private _isTouchDevice?: boolean;
    private _hasWebAnimations = false;
    private _hasAuditParam?: boolean;

    public isFirstPageLoad = true;

    constructor(
        private readonly metaService: MetaService,
        @Inject(PLATFORM_ID)
        private readonly platformId: Record<string, any>,
        @Optional()
        @Inject(REQUEST)
        private readonly request?: ExpressRequest
    ) {}

    public isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    public isServer(): boolean {
        return isPlatformServer(this.platformId);
    }

    /**
     * Check if site is in "audit" mode i.e. don't load 3rd-party code.
     * This is ok to run on server since the page will be cached with the query param in CF.
     */
    public isAudit(): boolean {
        if (this._hasAuditParam !== undefined) {
            return this._hasAuditParam;
        }

        const urlObj = new URL(this.metaService.getAbsoluteUrl());
        this._hasAuditParam = urlObj.searchParams.get('no-gtm') === 'true';

        return this._hasAuditParam;
    }

    public isTouchDevice(): boolean {
        if (this._isTouchDevice !== undefined) {
            return this._isTouchDevice;
        }

        // Link: https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
        if (this.isServer()) {
            this._isTouchDevice = false;
        } else {
            const mq = (queries: string) => {
                this._isTouchDevice = window.matchMedia(queries).matches;
                return this._isTouchDevice;
            };

            if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0) {
                return (this._isTouchDevice = true);
            }

            // include the 'heartz' as a way to have a non matching MQ to help terminate the join
            // https://git.io/vznFH
            const query = ['(', this.prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
            this._isTouchDevice = mq(query);
        }

        return this._isTouchDevice;
    }

    public hasIntersectionObserver(): boolean {
        return this.isBrowser() && 'IntersectionObserver' in window;
    }

    public supportsResizeObserver(): boolean {
        return this.isBrowser() && typeof window.ResizeObserver !== 'undefined';
    }

    public hasWebAnimation(): boolean {
        if (this._hasWebAnimations) {
            return true;
        }

        if (this.isBrowser()) {
            const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
            // Don't run animation, if user prefer reduced motion
            if (!mediaQuery || mediaQuery.matches) {
                return this._hasWebAnimations;
            }

            if ('animate' in window.document.body) {
                const el = document.createElement('div');
                const animation = el.animate([], undefined);
                if (animation && 'finished' in animation) {
                    this._hasWebAnimations = true;
                }
            }
        }

        return this._hasWebAnimations;
    }
}
